import { Col, DatePicker, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { productController } from '../../../controller/products.controller';
import dayjs from 'dayjs';
import { ProductEntry } from '../../../types/providersCoconut';
import { images } from '../../../config/images.path';
import { dataUser } from '../../../config/user.conf';
import { DataUser } from '../../../types/user';
import { StringFormatter } from '../../../util/string.formatter/string.formatter';
import { HomeMobileList } from './home.mobile.list';

const user: DataUser = dataUser.get('data');

const fontStyle = {
  fontSize: 20,
};

export const HomeMobileScreen = () => {
  const [loadingData, setLoadingData] = useState(false);

  const [commodity, setCommodity] = useState<ProductEntry[]>([]);

  const [totalReceivable, setTotalReceivable] = useState(0);
  const [totalReceived, setTotalReceived] = useState(0);
  const [totalDiscounts, setTotalDiscounts] = useState(0);

  useEffect(() => {
    getCommodity(dayjs().format());
  }, []);

  return (
    <Row justify={'center'} gutter={[0, 10]}>
      <Col span={24} className="text-center">
        <img src={images.logo} alt="Paraipaba Agroindustrial" width={100} />
      </Col>
      <Col span={22}>
        <h4>
          <strong>{`Olá, ${user.name}.`}</strong>
        </h4>
      </Col>
      <Col className="mb-2">
        <DatePicker
          picker="month"
          format={'MM/YYYY'}
          size="large"
          onChange={(date) => {
            getCommodity(dayjs(date).format());
          }}
        />
      </Col>
      <Col span={24} className="text-center text-success">
        <strong style={fontStyle}>
          Recebido: {StringFormatter.realNumber(totalReceived)}
        </strong>
      </Col>
      <Col span={24} className="text-center text-danger">
        <strong style={fontStyle}>
          A Receber: {StringFormatter.realNumber(totalReceivable)}
        </strong>
      </Col>
      <Col span={24} className="text-center">
        <strong style={fontStyle}>
          Descontos de Adiantamento: <br />
          {StringFormatter.realNumber(totalDiscounts)}
        </strong>
      </Col>

      <Col span={22}>
        <HomeMobileList commodity={commodity} loadingData={loadingData} />
      </Col>
    </Row>
  );

  async function getCommodity(localSelectedDate: string) {
    setLoadingData(true);

    const date = dayjs(localSelectedDate).format('YYYY-MM');

    const request = await productController.getCommodity(date);

    const data = request.data;

    if (data) {
      let productEntry: ProductEntry[] = [];

      const viewProvider = data?.viewProvider;
      productEntry = viewProvider;

      setTotalReceivable(data.aReceber);
      setTotalReceived(data.recebido);
      setTotalDiscounts(data.totalDescontos);

      setCommodity(productEntry);
      setLoadingData(false);
    }
  }
};
