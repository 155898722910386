import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, Row, notification } from 'antd';
import { productController } from '../../../../controller/products.controller';
import { IconType } from 'antd/es/notification/interface';
import br from 'antd/es/date-picker/locale/pt_BR';
import dayjs from 'dayjs';
import { ProviderCoconut } from '../../../../types/providersCoconut';
import { LectureCards } from './lecturer.cards';

const initialValues = {
  id: 0,
  nome: '',
  ficha: 0,
  frutosRecebidos: 0,
  volumeLitros: 0,
  dataHoraEntrada: '',
  dataHoraSaida: '',
  dataInicioParada: '',
  dataFimParada: '',
  dataDescarregamento: '',
  observacoes: '',
};

export const RegisterLecturer = () => {
  const [data, setData] = useState<ProviderCoconut[]>([]);

  const [values, setValues] = useState(initialValues);

  const [api, contextHolder] = notification.useNotification();

  const [loading, setLoading] = useState(false);

  const openNotification = (message: string, type: IconType) => {
    api.open({
      message: message,
      type: type,
      style: {
        backgroundColor: '#000',
      },
      duration: 5,
      placement: 'bottomRight',
    });
  };

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setValues({ ...values, [name]: value });
  }

  useEffect(() => {
    getSchedulings();
  }, []);

  return (
    <Row justify={'center'} className="mt-5 mb-5 border border-4 rounded-4">
      {contextHolder}
      <Col span={22}>
        <Row justify={'center'} gutter={[0, 50]}>
          <Col className="mt-5">
            <h2>
              <strong>Conferência de Carradas</strong>
            </h2>
          </Col>

          <Col span={24}>
            <p>
              Fornecedor: <strong>{values.nome}</strong> <br />
              Ficha: <strong>{values.ficha}</strong> <br />
            </p>
          </Col>

          <Col span={24}>
            <Form
              initialValues={{
                remember: true,
              }}
              fields={[
                {
                  name: 'frutosRecebidos',
                  value:
                    values.frutosRecebidos === 0 ? '' : values.frutosRecebidos,
                },
                {
                  name: 'volumeLitros',
                  value: values.volumeLitros === 0 ? '' : values.volumeLitros,
                },
              ]}
              autoComplete="off"
              layout="vertical"
              onFinish={save}
            >
              <Row justify={'center'} gutter={[10, 0]}>
                <Col md={12}>
                  <Form.Item
                    label="Frutos"
                    name="frutosRecebidos"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, digite a quatidade de frutos.',
                      },
                    ]}
                  >
                    <Input
                      disabled={!values.id}
                      name="frutosRecebidos"
                      onChange={handleChange}
                      type="number"
                      placeholder="Digite a quantidade de frutos..."
                    />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    label="Litros"
                    name="volumeLitros"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, digite a litragem.',
                      },
                    ]}
                  >
                    <Input
                      disabled={!values.id}
                      name="volumeLitros"
                      onChange={handleChange}
                      type="number"
                      placeholder="Digite a litragem..."
                    />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item
                    label="Data e Hora de Entrada"
                    name="dataHoraEntrada"
                  >
                    <DatePicker
                      disabled={!values.id}
                      onChange={(value) => {
                        const event: any = {
                          target: {
                            name: 'dataHoraEntrada',
                            value: dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
                          },
                        };
                        handleChange(event);
                      }}
                      showTime
                      format={'DD/MM/YYYY HH:mm:ss'}
                      locale={br}
                    />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item label="Data e Hora de Saida" name="dataHoraSaida">
                    <DatePicker
                      disabled={!values.id}
                      onChange={(value) => {
                        const event: any = {
                          target: {
                            name: 'dataHoraSaida',
                            value: dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
                          },
                        };
                        handleChange(event);
                      }}
                      format={'DD/MM/YYYY HH:mm:ss'}
                      showTime
                      locale={br}
                    />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item
                    label="Data/Hora de Desca..."
                    name="dataDescarregamento"
                  >
                    <DatePicker
                      disabled={!values.id}
                      onChange={(value) => {
                        const event: any = {
                          target: {
                            name: 'dataDescarregamento',
                            value: dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
                          },
                        };
                        handleChange(event);
                      }}
                      format={'DD/MM/YYYY HH:mm:ss'}
                      showTime
                      locale={br}
                    />
                  </Form.Item>
                </Col>

                <Col md={6}>
                  <Form.Item label="Inicio da Parada" name="dataInicioParada">
                    <DatePicker
                      disabled={!values.id}
                      onChange={(value) => {
                        const event: any = {
                          target: {
                            name: 'dataInicioParada',
                            value: dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
                          },
                        };
                        handleChange(event);
                      }}
                      format={'DD/MM/YYYY HH:mm:ss'}
                      showTime
                      locale={br}
                    />
                  </Form.Item>
                </Col>

                <Col md={6}>
                  <Form.Item label="Fim da Parada" name="dataFimParada">
                    <DatePicker
                      disabled={!values.id}
                      onChange={(value) => {
                        const event: any = {
                          target: {
                            name: 'dataFimParada',
                            value: dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
                          },
                        };
                        handleChange(event);
                      }}
                      format={'DD/MM/YYYY HH:mm:ss'}
                      showTime
                      locale={br}
                    />
                  </Form.Item>
                </Col>

                <Col md={6}>
                  <Form.Item label="Observações" name="observacoes">
                    <Input
                      disabled={!values.id}
                      name="observacoes"
                      onChange={handleChange}
                      type="string"
                      placeholder="Digite uma observação..."
                    />
                  </Form.Item>
                </Col>

                <Col span={22}>
                  <Form.Item>
                    <Row justify={'center'} gutter={[5, 0]}>
                      <Col md={4}>
                        <Button type="primary" htmlType="submit">
                          Salvar
                        </Button>
                      </Col>
                      <Col md={4}>
                        <Button
                          type="default"
                          htmlType="reset"
                          onClick={() => {
                            setValues(initialValues);
                          }}
                        >
                          Limpar
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>

          <Col>
            <DatePicker
              defaultValue={dayjs()}
              onChange={(value) => {
                getSchedulings(dayjs(value).format('YYYY-MM-DD'));
              }}
              format={'DD/MM/YYYY'}
              locale={br}
            />
          </Col>

          <Col span={24}>
            <LectureCards
              loading={loading}
              data={data}
              getValues={(values) => {
                setValues({
                  ...values,
                  id: values.id,
                  nome: values.nome,
                  ficha: values.numeroFicha,
                  dataHoraEntrada: values.dataEntrada,
                  dataDescarregamento: values.dataDescarregamento,
                  dataInicioParada: values.dataInicioParada,
                  dataFimParada: values.dataFimParada,
                  dataHoraSaida: values.dataSaida,
                  observacoes: values.observacoes,
                });
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );

  async function save() {
    const data = {
      id: values.id,
      frutosRecebidos: values.frutosRecebidos,
      volumeLitros: values.volumeLitros,
      dataHoraEntrada: values.dataHoraEntrada,
      dataHoraSaida: values.dataHoraSaida,
      dataInicioParada: values.dataInicioParada,
      dataFimParada: values.dataFimParada,
      dataDescarregamento: values.dataDescarregamento,
      observacoes: values.observacoes,
    };

    const alright = await productController.patcheReceivingCargo(data);

    const message: string | any = alright.message;

    if (!alright.error) {
      openNotification(message, 'success');
    } else {
      openNotification(message, 'error');
    }

    setTimeout(() => {
      getSchedulings();
    }, 500);
  }

  async function getSchedulings(value?: string) {
    setData([]);
    setLoading(true);

    const date = value ? value : dayjs().format('YYYY-MM-DD');

    const data: any[] = await productController.getScheduling(date);
    setData(data);

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }
};
