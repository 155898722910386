import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Login } from '../screens/login';
import { Footer } from '../screens/ui/footer';
import { Header } from '../screens/ui/header';
import { Home } from '../screens/system/home/receipt';
import { DataUser, UserType } from '../types/user';
import { ReceivingCargo } from '../screens/system/registers/cargo/receiving.cargo';
import { RegisterLecturer } from '../screens/system/registers/lecturer/register.lecturer';
import { Register } from '../screens/system/registers';
import { HomeMobile } from '../screens/system.mobile/home';

interface Props {
  dataUser: DataUser;
  screenMobile: boolean;
}

export const AppRoutes = (props: Props) => {
  const screenMobile = props.screenMobile;

  return isLogged();

  function isLogged() {
    const user = props.dataUser;

    if (user) {
      const userType: UserType = user.userType;

      if (userType === 'FORNECEDOR') {
        if (screenMobile) {
          return (
            <Routes>
              <Route path="*" index element={HomeMobile()} />
            </Routes>
          );
        }
        return <></>;
      }

      if (userType === 'CONFERENTE') {
        return (
          <>
            <Header />
            <Routes>
              <Route path="*" index element={RegisterLecturer()} />
            </Routes>
            <Footer />
          </>
        );
      }

      if (userType === 'PORTARIA') {
        return (
          <>
            <Header />
            <Routes>
              <Route path="*" index element={ReceivingCargo()} />
            </Routes>
            <Footer />
          </>
        );
      }

      if (userType === 'RECEBIMENTO') {
        return (
          <>
            <Header />
            <Routes>
              <Route path="*" index element={Home()} />
              <Route path="/register" index element={Register()} />
            </Routes>
            <Footer />
          </>
        );
      }

      return (
        <>
          <Header />
          <Routes>
            <Route path="*" index element={Home()} />
          </Routes>
          <Footer />
        </>
      );
    }

    return (
      <Routes>
        <Route index path="*" element={Login()} />
      </Routes>
    );
  }
};
