/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import './register.css';
import Table, { ColumnsType, ColumnType, TableProps } from 'antd/es/table';
import {
  Button,
  Form,
  Input,
  InputRef,
  notification,
  Select,
  Space,
} from 'antd';
import { AiOutlineSearch } from 'react-icons/ai';
import { FilterConfirmProps, FilterValue } from 'antd/es/table/interface';
import { IconType } from 'antd/es/notification/interface';
import { ProviderUser } from '../../../types/user';
import { userController } from '../../../controller/user.controller';

interface DataType {
  key: number;
  cpfCnpj: string;
  nome: string;
  senha: string;
  userType: string;
}

type DataIndex = keyof DataType;

const initialProvidersValues: ProviderUser[] = [];

const initialValues = {
  cpfCnpj: '',
  nome: '',
  senha: '',
  userType: 'FORNECEDOR',
};

export const RegisterProvidersScreen = () => {
  const searchInput = useRef<InputRef>(null);

  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});

  const [data, setData] = useState<DataType[]>([]);

  const [valuesProviders, setValuesProviders] = useState<ProviderUser[]>(
    initialProvidersValues,
  );

  const [loading, setLoading] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  const [values, setValues] = useState(initialValues);

  const hadleChange = (event: any) => {
    const { name, value } = event.target;

    setValues({ ...values, [name]: value });
  };

  const openNotification = (message: string, type: IconType) => {
    api.open({
      message: message,
      type: type,
      style: {
        backgroundColor: '#000',
      },
      duration: 5,
      placement: 'bottomRight',
    });
  };

  const handleChange: TableProps<DataType>['onChange'] = (
    pagination,
    filters,
  ) => {
    setFilteredInfo(filters);
  };

  const clearAllFilters = () => {
    setFilteredInfo({});
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Pesquisar...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], () =>
                confirm({ closeDropdown: false }),
              )
            }
            icon={<AiOutlineSearch />}
            size="small"
            style={{ width: 90 }}
          >
            Pesquisar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <AiOutlineSearch
        size={20}
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    render: (text) => text,
  });

  const columns: ColumnsType<DataType> = [
    {
      key: 'cpfCnpj',
      title: 'CPF/Cnpj',
      dataIndex: 'cpfCnpj',
      filteredValue: filteredInfo.cpfCnpj || null,
      ...getColumnSearchProps('cpfCnpj'),
    },
    {
      key: 'nome',
      title: 'Fornecedor',
      dataIndex: 'nome',
      filteredValue: filteredInfo.nome || null,
      sorter: (a, b) => a.nome.localeCompare(b.nome),
      showSorterTooltip: {
        title: 'Clique para alterar a ordem.',
        color: '#000',
      },
      ...getColumnSearchProps('nome'),
    },
    {
      key: 'userType',
      title: 'Tipo',
      dataIndex: 'userType',
      filteredValue: filteredInfo.userType || null,
      sorter: (a, b) => a.userType.localeCompare(b.userType),
      showSorterTooltip: {
        title: 'Clique para alterar a ordem.',
        color: '#000',
      },
      ...getColumnSearchProps('userType'),
    },
    {
      key: 'senha',
      title: 'Senha',
      filteredValue: null,
      render: (values: DataType) => {
        return (
          <Input.Password
            onChange={(value: any) => {
              const senha: string = value.target.value;
              values.senha = senha;

              let user: ProviderUser = valuesProviders.filter(
                (value) => value.cpfCnpj === values.cpfCnpj,
              )[0];

              user = { ...user, senha: senha };

              storeAlterTable(user);
            }}
            defaultValue={values.senha}
          />
        );
      },
    },
  ];

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="container-home mt-5 border border-4 rounded-4">
      <div className="row m-3 justify-content-center">
        <div className="row justify-content-center text-center m-5">
          <div className="col">
            <h3>
              <strong>Cadastro</strong>
            </h3>
          </div>
        </div>

        <div className="row m-0 mt-5 mb-5 justify-content-center">
          <Container fluid>
            {contextHolder}
            <Form
              layout="vertical"
              onFinish={save}
              autoComplete="off"
              requiredMark={false}
              fields={[
                { name: 'cpfCnpj', value: values.cpfCnpj },
                { name: 'nome', value: values.nome },
                { name: 'senha', value: values.senha },
              ]}
            >
              <Row className="justify-content-center">
                <Col md={3}>
                  <Row>
                    <Form.Item
                      label="CPF/ CNPJ"
                      name="cpfCnpj"
                      rules={[
                        {
                          message: 'Campo Obrigatório.',
                          required: true,
                        },
                      ]}
                    >
                      <Input name="cpfCnpj" onChange={hadleChange} />
                    </Form.Item>
                  </Row>
                </Col>
                <Col md={3}>
                  <Row>
                    <Form.Item
                      label="Nome"
                      name="nome"
                      rules={[
                        {
                          message: 'Campo Obrigatório.',
                          required: true,
                        },
                      ]}
                    >
                      <Input name="nome" onChange={hadleChange} />
                    </Form.Item>
                  </Row>
                </Col>
                <Col md={3}>
                  <Row>
                    <Form.Item
                      label="Senha"
                      name="senha"
                      rules={[
                        {
                          message: 'Campo Obrigatório.',
                          required: true,
                        },
                      ]}
                    >
                      <Input.Password name="senha" onChange={hadleChange} />
                    </Form.Item>
                  </Row>
                </Col>
                <Col md={3}>
                  <Row>
                    <Form.Item
                      label="Tipo"
                      name="userType"
                      rules={[
                        {
                          message: 'Campo Obrigatório.',
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        defaultValue={'FORNECEDOR'}
                        onChange={(value: string) => {
                          setValues({ ...values, userType: value });
                        }}
                        options={[
                          { value: 'FORNECEDOR', label: 'FORNECEDOR' },
                          { value: 'ADM', label: 'ADM' },
                          { value: 'OPERADOR', label: 'OPERADOR' },
                          {
                            value: 'RECEBIMENTO',
                            label: 'RECEBIMENTO',
                          },
                          {
                            value: 'PORTARIA',
                            label: 'PORTARIA',
                          },
                          {
                            value: 'CONFERENTE',
                            label: 'CONFERENTE',
                          },
                        ]}
                      />
                    </Form.Item>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs={1} className="m-4">
                  <Button htmlType="submit" disabled={loading}>
                    {!loading && <span>Enviar</span>}
                    {loading && (
                      <Spinner
                        as="span"
                        animation="grow"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </Button>
                </Col>

                <Col xs={1} className="m-4">
                  <Button
                    disabled={loading}
                    onClick={() => {
                      setValues(initialValues);
                    }}
                  >
                    {!loading && <span>Limpar</span>}
                    {loading && (
                      <Spinner
                        as="span"
                        animation="grow"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>

        <div className="row justify-content-center">
          <div className="row justify-content-center text-center m-5">
            <div className="col">
              <h3>
                <strong>Mudar Senha</strong>
              </h3>
            </div>
          </div>
        </div>

        <div className="row justify-content-center text-center m-4">
          <div className="col-2">
            <Button onClick={patch} disabled={loading}>
              {!loading && <span>Salvar</span>}
              {loading && (
                <Spinner
                  as="span"
                  animation="grow"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
          <div className="col-2">
            <Button onClick={clearAllFilters} disabled={loading}>
              {!loading && <span>Limpar</span>}
              {loading && (
                <Spinner
                  as="span"
                  animation="grow"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
          <div className="col-2">
            <Button onClick={getUsers} disabled={loading}>
              {!loading && <span>Atualizar</span>}
              {loading && (
                <Spinner
                  as="span"
                  animation="grow"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
        </div>

        <div className="row">
          {contextHolder}
          <Table
            onChange={handleChange}
            scroll={{ y: 400 }}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={{
              defaultPageSize: 10,
              pageSizeOptions: [10, 20, 100],
              showTotal: () => (
                <div className="text-white">
                  <strong>Total: {data.length}</strong>
                </div>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );

  async function save() {
    setLoading(true);

    const valuesToSave: ProviderUser = {
      cpfCnpj: values.cpfCnpj,
      nome: values.nome,
      senha: values.senha,
      userType: values.userType,
    };

    const alright = await userController.store(valuesToSave);

    const message: string | any = alright.message;

    if (!alright.error) {
      openNotification(message, 'success');
      setValues(initialValues);
    } else {
      openNotification(message, 'error');
    }

    getUsers();
  }

  async function patch() {
    const valuesToSave: ProviderUser[] = addUsers();

    if (!valuesToSave.length) {
      openNotification('Nenhum valor foi alterado', 'warning');
      return;
    }

    setLoading(true);

    const alright = await userController.patchs(valuesToSave);

    const message: string | any = alright.message;

    if (!alright.error) {
      openNotification(message, 'success');
    } else {
      openNotification(message, 'error');
    }

    getUsers();
  }

  async function getUsers() {
    setLoading(true);

    setData([]);
    setValuesProviders([]);

    const data: ProviderUser[] = await userController.get();
    setValuesProviders(data);

    setTimeout(() => {
      setLoading(false);
      startDataType(data);
      clearAllFilters();
    }, 1000);
  }

  function storeAlterTable(pay: ProviderUser) {
    const user: ProviderUser = pay;

    const value = valuesProviders.filter(
      (value) => value.cpfCnpj === user.cpfCnpj,
    )[0];

    const index = valuesProviders.indexOf(value);

    valuesProviders.splice(index, 1, { ...user });
  }

  function addUsers(): ProviderUser[] {
    const valuesToSave = valuesProviders.filter(
      (value) => value.senha.length !== 0,
    );

    return valuesToSave;
  }

  function startDataType(providers: ProviderUser[]) {
    const data: DataType[] = [];

    providers.map((value, index) => {
      data.push({
        key: index,
        cpfCnpj: value.cpfCnpj,
        nome: value.nome,
        senha: value.senha,
        userType: value.userType,
      });
    });

    setData(data);
  }
};
