import { Button, notification } from 'antd';
import { IconType } from 'antd/es/notification/interface';
import React, { useState } from 'react';
import { Row, Form, Col, Spinner } from 'react-bootstrap';
import { productController } from '../../../../controller/products.controller';

export const HomeUploadCSVScreen = () => {
  const [file, setFile] = useState<any>();

  const [api, contextHolder] = notification.useNotification();

  const [loading, setLoading] = useState(false);

  const handleUploadFile = (e: any) => setFile(e.target.files[0]);

  const openNotification = (message: string, type: IconType) => {
    api.open({
      message: message,
      type: type,
      style: {
        backgroundColor: '#000',
      },
      duration: 5,
      placement: 'bottomRight',
    });
  };

  return (
    <div className="mt-5 border border-4 rounded-4">
      {contextHolder}
      <div className="row m-3 justify-content-center">
        <Row className="justify-content-center text-center m-5">
          <div className="col">
            <h3>
              <strong>Upload de dados de carregamentos</strong>
            </h3>
          </div>
        </Row>

        <Row>
          <Form onSubmit={save}>
            <Row>
              <Col>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Arquivo:</Form.Label>
                  <Form.Control
                    accept="application/csv"
                    onChange={handleUploadFile}
                    name="file"
                    type="file"
                    multiple={true}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-5 mb-5">
              <Col>
                <Button disabled={loading} htmlType="submit">
                  {!loading && <span>Enviar</span>}
                  {loading && (
                    <Spinner
                      as="span"
                      animation="grow"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        </Row>
      </div>
    </div>
  );

  async function save(event: React.FormEvent) {
    event.preventDefault();

    if (!file) {
      openNotification('Nenhum arquivo foi selecionado.', 'warning');
      setLoading(false);
      return;
    }

    setLoading(true);

    const data = {
      file: file,
    };

    const alright = await productController.store(data);

    const message: string | any = alright.message;

    const notificationType: IconType = alright.error ? 'error' : 'success';

    openNotification(message, notificationType);
    setLoading(false);
  }
};
