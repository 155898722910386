import React, { useEffect, useState, useRef } from 'react';
import { ListGroup, Spinner, Form as FormBoots } from 'react-bootstrap';
import { ProviderCoconut } from '../../../../types/providersCoconut';
import { productController } from '../../../../controller/products.controller';
import { ColumnsType, TableProps } from 'antd/es/table';
import { notification, Table, Space, DatePicker, Form, Row, Col } from 'antd';
import {
  ColumnType,
  FilterConfirmProps,
  FilterValue,
} from 'antd/es/table/interface';
import Input, { InputRef } from 'antd/es/input/Input';
import Button from 'antd/es/button';
import { AiOutlineSearch } from 'react-icons/ai';
import { IconType } from 'antd/es/notification/interface';
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';

interface DataType {
  key: number;
  id: number;
  cpfCnpj: string;
  numeroNotaFiscal: number;
  nome: string;
  dataDescarregamento: string;
  dataPagamento: string;
  pago: boolean;
  aPagar: string;
  debitarAdiantamento: string;
  total: string;
  observacoes: string;
  atualizadoEm: string;
}

type DataIndex = keyof DataType;

const initialProvidersValues: ProviderCoconut[] = [];

const initialValuesDates = [dayjs(), dayjs()];

export const HomePaymentsScreen = () => {
  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});

  const searchInput = useRef<InputRef>(null);

  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState<DataType[]>([]);

  const [valuesProviders, setValuesProviders] = useState<ProviderCoconut[]>(
    initialProvidersValues,
  );

  const [totalSelected, setTotalSelected] = useState<number>(0);

  const [loading, setLoading] = useState(false);

  const [dates, setDates] = useState(initialValuesDates);

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day');
  };

  const handleChange: TableProps<DataType>['onChange'] = (
    pagination,
    filters,
  ) => {
    setFilteredInfo(filters);
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
  ) => {
    setLoading(true);
    confirm();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const clearAllFilters = () => {
    setDates(initialValuesDates);
    setFilteredInfo({});
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const openNotification = (message: string, type: IconType) => {
    api.open({
      message: message,
      type: type,
      style: {
        backgroundColor: '#000',
      },
      duration: 5,
      placement: 'bottomRight',
    });
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Pesquisar...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], () =>
                confirm({ closeDropdown: false }),
              )
            }
            icon={<AiOutlineSearch />}
            size="small"
            style={{ width: 90 }}
          >
            Pesquisar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <AiOutlineSearch
        size={20}
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    render: (text) => text,
  });

  const columns: ColumnsType<DataType> = [
    {
      key: 'numeroNotaFiscal',
      title: 'Nf-e',
      dataIndex: 'numeroNotaFiscal',
      width: 150,
      filteredValue: filteredInfo.numeroNotaFiscal || null,
      sorter: (a, b) => a.numeroNotaFiscal - b.numeroNotaFiscal,
      ...getColumnSearchProps('numeroNotaFiscal'),
    },
    {
      key: 'cpfCnpj',
      title: 'CPF/Cnpj',
      dataIndex: 'cpfCnpj',
      width: 200,
      filteredValue: filteredInfo.cpfCnpj || null,
      ...getColumnSearchProps('cpfCnpj'),
    },
    {
      key: 'nome',
      title: 'Fornecedor',
      dataIndex: 'nome',
      width: 250,
      filteredValue: filteredInfo.nome || null,
      sorter: (a, b) => a.nome.localeCompare(b.nome),
      ...getColumnSearchProps('nome'),
    },
    {
      key: 'dataDescarregamento',
      title: 'Data de entrega',
      dataIndex: 'dataDescarregamento',
      filteredValue: filteredInfo.dataDescarregamento || null,
      ...getColumnSearchProps('dataDescarregamento'),
    },
    {
      key: 'dataPagamento',
      title: 'Data de pagamento',
      dataIndex: 'dataPagamento',
      filteredValue: filteredInfo.dataPagamento || null,
      ...getColumnSearchProps('dataPagamento'),
    },
    {
      key: 'pago',
      title: 'Pago',
      filteredValue: null,
      width: 100,
      render: (values: DataType) => {
        return (
          <>
            <FormBoots.Check
              type="switch"
              className="m-0"
              onChange={() => {
                values.pago = !values.pago;

                let payment: ProviderCoconut = valuesProviders.filter(
                  (value) => value.id === values.id,
                )[0];

                payment = { ...payment, pago: values.pago };

                storeAlterTable(payment);
              }}
              defaultChecked={values.pago}
            />
          </>
        );
      },
    },
    {
      key: 'aPagar',
      title: 'A Pagar',
      dataIndex: 'aPagar',
      filteredValue: filteredInfo.aPagar || null,
    },
  ];

  useEffect(() => {
    getProductsPayables();
  }, []);

  return (
    <Row justify={'center'} className="mt-5 border border-4 rounded-4">
      {contextHolder}
      <Col span={24}>
        <Row justify={'center'} className="text-center mt-5">
          <Col span={24}>
            <h3>
              <strong>Pagamentos Confirmados</strong>
            </h3>
          </Col>
        </Row>

        <Row justify={'center'} className="text-center mt-5">
          <Col span={24}>
            <Form
              id="form"
              name="basic"
              requiredMark={false}
              autoComplete="on"
              scrollToFirstError={true}
            >
              <DatePicker.RangePicker
                name="Selecione os meses"
                value={[dates[0], dates[1]]}
                disabledDate={disabledDate}
                format={'DD/MM/YYYY'}
                onChange={(value: any) => {
                  if (value !== null) {
                    setDates(value);
                    return;
                  }
                  setDates(initialValuesDates);
                }}
                size="large"
              />
            </Form>
          </Col>
        </Row>

        <Row justify={'center'} className="text-center mt-5">
          <Col md={4}>
            <Button onClick={save} disabled={loading}>
              {!loading && <span>Salvar</span>}
              {loading && (
                <Spinner
                  as="span"
                  animation="grow"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </Col>
          <Col md={4}>
            <Button onClick={clearAllFilters} disabled={loading}>
              {!loading && <span>Limpar</span>}
              {loading && (
                <Spinner
                  as="span"
                  animation="grow"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </Col>
          <Col md={4}>
            <Button onClick={getProductsPayables} disabled={loading}>
              {!loading && <span>Atualizar</span>}
              {loading && (
                <Spinner
                  as="span"
                  animation="grow"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </Col>
        </Row>

        <Row justify={'end'} className="text-center m-1">
          <Col md={6}>
            <h5>
              Total:{' '}
              {totalSelected.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </h5>
          </Col>
        </Row>

        <Row justify={'center'} className="m-4">
          <Col span={24}>
            <Table
              onChange={handleChange}
              scroll={{ y: 400 }}
              columns={columns}
              loading={loading}
              expandable={{
                expandedRowRender: (record) => (
                  <ListGroup>
                    <Row className="background text">
                      <Col xs={4} md={4}>
                        <ListGroup.Item className="background text border-0">
                          <p style={{ margin: 0 }}>
                            Debitado: {record.debitarAdiantamento}
                          </p>
                        </ListGroup.Item>
                      </Col>
                      <Col xs={4} md={4}>
                        <ListGroup.Item className="background text border-0">
                          <p style={{ margin: 0 }}>
                            Total Pago: {record.total}
                          </p>
                        </ListGroup.Item>
                      </Col>

                      <Col xs={8} md={8}>
                        <ListGroup.Item className="background text border-0">
                          <p style={{ margin: 0 }}>
                            Observações: {record.observacoes}
                          </p>
                        </ListGroup.Item>
                      </Col>
                    </Row>
                  </ListGroup>
                ),
              }}
              dataSource={data}
              pagination={{
                defaultPageSize: 10,
                pageSizeOptions: [10, data.length],
                showTotal: () => (
                  <div className="text-white">
                    <strong>Notas: {data.length}</strong>
                  </div>
                ),
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );

  async function save() {
    setLoading(true);

    const valuesToSave: ProviderCoconut[] = addPayments();

    if (!valuesToSave.length) {
      openNotification('Nenhum dado foi alterado.', 'warning');
      getProductsPayables();
      return;
    }

    const alright = await productController.patchPayments(valuesToSave);

    const message: string | any = alright.message;

    if (!alright.error) {
      valuesToSave.length = 0;
      openNotification(message, 'success');
    } else {
      openNotification(message, 'error');
    }

    getProductsPayables();
    clearAllFilters();
  }

  async function getProductsPayables() {
    setLoading(true);

    setData([]);
    setTotalSelected(0);

    const startDate = dayjs(dates[0]).format('YYYY-MM-DD HH:mm:ss');
    const endDate = dayjs(dates[1]).format('YYYY-MM-DD HH:mm:ss');

    const data: ProviderCoconut[] = await productController.getPayables(
      startDate,
      endDate,
    );

    setValuesProviders(data);

    setTimeout(() => {
      startDataType(data);
      sumTotalSelected(data);
      setLoading(false);
    }, 1000);
  }

  function startDataType(providers: ProviderCoconut[]) {
    const data: DataType[] = [];

    // eslint-disable-next-line array-callback-return
    providers.map((value, index) => {
      const debitarAdiantamento = value.debitarAdiantamento
        ? value.debitarAdiantamento
        : 0;

      let nome = value.nome;
      if (nome.substring(0, 1) === ' ') {
        nome = value.nome.replace(' ', '');
      }

      data.push({
        key: index,
        id: value.id,
        aPagar: value.aPagar.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
        cpfCnpj: value.cpfCnpj,
        dataDescarregamento: value.dataDescarregamento.toString(),
        debitarAdiantamento: debitarAdiantamento.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
        pago: value.pago,
        nome: nome,
        numeroNotaFiscal: value.numeroNotaFiscal,
        total: value.total.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
        dataPagamento: dayjs(value.dataPagamento).format('DD/MM/YYYY'),
        observacoes: value.observacoes,
        atualizadoEm: value.atualizadoEm,
      });
    });

    setData(data);
  }

  function storeAlterTable(pay: ProviderCoconut) {
    const payment: ProviderCoconut = pay;

    const value = valuesProviders.filter((value) => value.id === payment.id)[0];

    const index = valuesProviders.indexOf(value);

    valuesProviders.splice(index, 1, { ...payment });
  }

  function addPayments(): ProviderCoconut[] {
    const valuesToSave = valuesProviders.filter(
      (value) => value.pago === false,
    );

    return valuesToSave;
  }

  function sumTotalSelected(providersCoconut: ProviderCoconut[]) {
    const values = providersCoconut.filter((value) => value.pago === true);

    let totalSelected = 0;

    values.map((value) => {
      return (totalSelected += value.aPagar);
    });

    setTotalSelected(totalSelected);
  }
};
