import * as Cookies from 'react-use-cookie';
import { KeyUser } from '../types/user';

export const dataUser = {
  store: (key: KeyUser, value: any) => {
    const toJSONValue = JSON.stringify(value);

    Cookies.setCookie(key, toJSONValue, {
      days: 30,
      SameSite: 'Lax',
    });
  },

  get: (key: KeyUser) => {
    const values = Cookies.getCookie(key);
    const JSONValue = values ? JSON.parse(values) : '';
    return JSONValue;
  },

  remove: (key: KeyUser) => {
    document.cookie = key + '=;expires=0;SameSite=Lax';
  },

  removeAll: () => {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + '=;expires=0;SameSite=Lax';
    }
  },
};
