import { Button, Card, Col, Row } from 'antd';
import React from 'react';
import { ProviderCoconut } from '../../../../types/providersCoconut';
import dayjs from 'dayjs';
import { AiFillEdit } from 'react-icons/ai';

interface Props {
  data: ProviderCoconut[];
  loading: boolean;
  getValues: (values: ProviderCoconut) => void;
}

export const LectureCards = (props: Props) => {
  const data = props.data;
  const loading = props.loading;

  return (
    <Row className="mb-5">
      <Col
        span={24}
        className="overflow-auto border rounded-3"
        style={{ maxHeight: 350 }}
      >
        <Row justify={'center'} gutter={[16, 40]} className="m-4">
          {data.map((value, index) => {
            const dataAgendada = dayjs(value.dataAgendada).format('DD/MM/YYYY');
            return (
              <Col md={22} key={index}>
                <Card
                  loading={loading}
                  title={
                    <span>
                      <h6>
                        <strong>Fornecedor: {value.nome}</strong>
                      </h6>
                    </span>
                  }
                  bordered={true}
                >
                  <Row justify={'center'} gutter={20}>
                    <Col span={24}>
                      <p>
                        <strong>
                          Motorista: {value.motorista}
                          <br />
                          Veículo: {value.veiculo}
                          <br />
                          Placa: {value.placa}
                          <br />
                          Agendado Para: {dataAgendada}
                          <br />
                          Ficha: {value.numeroFicha}
                          <br />
                          Frutos: {value.frutosRecebidos}
                          <br />
                          Litros: {value.volumeLitros}
                        </strong>
                      </p>
                    </Col>
                    <Col>
                      <Button
                        onClick={() => {
                          props.getValues(value);
                        }}
                        size="large"
                        type="dashed"
                      >
                        <AiFillEdit size={30} />
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};
