import {
  Button,
  Col,
  Input,
  InputRef,
  Popconfirm,
  Row,
  Space,
  Table,
} from 'antd';
import {
  ColumnType,
  ColumnsType,
  FilterConfirmProps,
  FilterValue,
} from 'antd/es/table/interface';
import { TableProps } from 'antd/lib';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import { AiFillEdit, AiOutlineSearch } from 'react-icons/ai';
import { BsTrash2Fill } from 'react-icons/bs';

interface DataType {
  key: number;
  id: number;
  cpfCnpj: string;
  nome: string;
  motorista: string;
  dataAgendada: string;
  precoLitro: string;
  numeroFicha: string;
  numeroCarrada: string;
  placa: string;
  veiculo: string;
  liberada: boolean;
  cidade: string;
  dataColheita: string;
  dataChegada: string;
}

type DataIndex = keyof DataType;

interface Props {
  valuesTable: any[];
  loading: boolean;
  getRowValues: (values: any) => any;
  disapprovedCargo: (id: number) => any;
}

export const CollectRegisterTable = (props: Props) => {
  const data = props.valuesTable;
  const loading = props.loading;

  const searchInput = useRef<InputRef>(null);

  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const handleChange: TableProps<DataType>['onChange'] = (
    pagination,
    filters,
  ) => {
    setFilteredInfo(filters);
  };

  const confirm = (id: number) => {
    props.disapprovedCargo(id);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Pesquisar...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], () =>
                confirm({ closeDropdown: false }),
              )
            }
            icon={<AiOutlineSearch />}
            size="small"
            style={{ width: 90 }}
          >
            Pesquisar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <AiOutlineSearch
        size={20}
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    render: (text) => text,
  });

  const columns: ColumnsType<DataType> = [
    {
      key: 'cpfCnpj',
      title: 'CPF/Cnpj',
      dataIndex: 'cpfCnpj',
      fixed: 'left',
      width: 150,
      filteredValue: filteredInfo.cpfCnpj || null,
      ...getColumnSearchProps('cpfCnpj'),
    },
    {
      key: 'nome',
      title: 'Fornecedor',
      dataIndex: 'nome',
      width: 220,
      filteredValue: filteredInfo.nome || null,
      sorter: (a, b) => a.nome.localeCompare(b.nome),
      showSorterTooltip: {
        title: 'Clique para alterar a ordem.',
        color: '#000',
      },
      ...getColumnSearchProps('nome'),
    },
    {
      key: 'liberada',
      title: 'Liberada',
      dataIndex: 'liberada',
      width: 150,
      render: (data) => {
        return <div>{data ? 'Sim' : 'Não'}</div>;
      },
    },
    {
      key: 'precoLitro',
      title: 'Preço.L',
      dataIndex: 'precoLitro',
      width: 150,
      filteredValue: null,
    },
    {
      key: 'veiculo',
      title: 'Veiculo',
      width: 150,
      dataIndex: 'veiculo',
      filteredValue: null,
    },
    {
      key: 'numeroFicha',
      title: 'Numero Da Ficha',
      width: 150,
      dataIndex: 'numeroFicha',
      filteredValue: null,
    },
    {
      key: 'dataColheita',
      title: 'Data da Colheita',
      dataIndex: 'dataColheita',
      width: 150,
      filteredValue: null,
    },
    {
      key: 'dataChegada',
      title: 'Data Chegada',
      width: 150,
      dataIndex: 'dataChegada',
      filteredValue: null,
    },
    {
      key: 'dataAgendada',
      title: 'Data Agendada',
      width: 150,
      dataIndex: 'dataAgendada',
      filteredValue: null,
    },
    {
      title: 'Operação',
      key: 'operation',
      fixed: 'right',
      width: 190,
      render: (data: DataType) => (
        <Row gutter={[30, 0]}>
          <Col>
            <Button
              href="#cargo"
              title="Editar"
              onClick={() => {
                props.getRowValues({
                  id: data.id,
                  fornecedor: data.nome,
                  motorista: data.motorista,
                  placa: data.placa,
                  veiculo: data.veiculo,
                  precoLitro: data.precoLitro,
                  liberada: data.liberada,
                  numeroFicha: data.numeroFicha,
                  numeroCarrada: data.numeroCarrada,
                  cidade: data.cidade,
                  dataColheita: data.dataColheita,
                  dataChegada: data.dataChegada,
                });
              }}
            >
              <AiFillEdit size={20} title="Editar" />
            </Button>
          </Col>
          <Col>
            <Popconfirm
              title="Reprovar Carrada"
              description="Deseja reprovar a carrada?"
              onConfirm={() => confirm(data.id)}
              okText="Sim"
              cancelText="Não"
            >
              <Button danger>
                <BsTrash2Fill />
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <Row>
      <Col>
        <Table
          onChange={handleChange}
          scroll={{ y: 400, x: 200 }}
          loading={loading}
          columns={columns}
          dataSource={startDataType()}
          pagination={{
            defaultPageSize: 10,
            pageSizeOptions: [10, 20, 100],
            showTotal: () => (
              <div className="text-white">
                <strong>Total: {data.length}</strong>
              </div>
            ),
          }}
        />
      </Col>
    </Row>
  );

  function startDataType() {
    const newData: any = data.map((value) => {
      const date = dayjs(value.dataAgendada).format('DD/MM/YYYY');
      const dataColheita = value.dataColheita;
      const dataChegada = value.dataChegada;
      return {
        key: value.id,
        id: value.id,
        cpfCnpj: value.cpfCnpj,
        nome: value.nome,
        dataAgendada: date,
        motorista: value.motorista,
        placa: value.placa,
        liberada: value.liberada,
        veiculo: value.veiculo,
        precoLitro: value.precoLitro,
        numeroFicha: value.numeroFicha,
        numeroCarrada: value.numeroCarrada,
        cidade: value.cidade,
        dataColheita: dataColheita
          ? dayjs(dataColheita).format('DD/MM/YYYY')
          : '',
        dataChegada: dataChegada
          ? dayjs(dataChegada).format('DD/MM/YYYY HH:mm:ss')
          : '',
      };
    });

    return newData;
  }
};
