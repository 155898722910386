import axios from '../config/axios';
import { dataUser } from '../config/user.conf';
import { ProviderCoconut } from '../types/providersCoconut';
import { serverError } from './errors/axios.errors.controller';

export const productController = {
  store: async (value: any) => {
    try {
      const dataProducts = new FormData();
      dataProducts.append('file', value.file as Blob);

      const user = dataUser.get('data');

      const token: string = user.token;

      const request = await axios.post('/provider/', dataProducts, {
        headers: { authorization: `Bearer ${token}` },
      });

      const data = request.data;

      const message: string = data.message as string;

      return { error: false, message };
    } catch (error: any) {
      const message: string | any = await serverError.check(error);
      return { error: true, message };
    }
  },

  storeScheduling: async (dataScheduling: any) => {
    let request;
    let data;

    const schedulingData = {
      data: dataScheduling.data,
      date: dataScheduling.date,
    };

    try {
      const user = dataUser.get('data');

      const token: string = user.token;

      request = await axios.post(
        '/scheduling',
        { ...schedulingData },
        {
          headers: { authorization: `Bearer ${token}` },
        },
      );

      data = request.data;

      const message = data.message;

      return { error: false, message };
    } catch (error: any) {
      const requestData = error;
      const data = requestData.response.data;
      if (!data) {
        return { error: true, message: 'Erro: O servidor está em manutenção.' };
      }
      const message = data.message;

      return { error: true, message };
    }
  },

  getScheduling: async (date?: string) => {
    try {
      const user = dataUser.get('data');

      const token: string = user.token;

      const request = await axios.get(`/provider/scheduling/${date}`, {
        headers: { authorization: `Bearer ${token}` },
      });

      const data = request.data;

      return data.data;
    } catch (error) {
      const message: string | any = await serverError.check(error);
      return { error: true, message };
    }
  },

  getProvisions: async (date?: string) => {
    try {
      const user = dataUser.get('data');

      const token: string = user.token;

      const request = await axios.get(`/provider/provisions/${date}`, {
        headers: { authorization: `Bearer ${token}` },
      });

      const data = request.data;

      return data.data;
    } catch (error) {
      const message: string | any = await serverError.check(error);
      return { error: true, message };
    }
  },

  getCommodity: async (date: string) => {
    try {
      const user = dataUser.get('data');

      const token: string = user.token;

      const request = await axios.get(`/provider/products/${date}`, {
        headers: { authorization: `Bearer ${token}` },
      });

      const data = request.data;

      return data;
    } catch (error) {
      const message: string | any = await serverError.check(error);
      return { error: true, message };
    }
  },

  getNoPayables: async () => {
    try {
      const user = dataUser.get('data');

      const token: string = user.token;

      const request = await axios.get('/provider/products/no-payable', {
        headers: { authorization: `Bearer ${token}` },
      });

      const data = request.data;

      return data.data;
    } catch (error) {
      const message: string | any = await serverError.check(error);
      return { error: true, message };
    }
  },

  getPayables: async (start: string, end: string) => {
    try {
      const user = dataUser.get('data');

      const token: string = user.token;

      const request = await axios.get(
        `/provider/products/payable/${start}/${end}`,
        {
          headers: { authorization: `Bearer ${token}` },
        },
      );

      const data = request.data;

      return data.data;
    } catch (error) {
      const message: string | any = await serverError.check(error);
      return { error: true, message };
    }
  },

  patcheReceivingCargo: async (receiving: any) => {
    let request;
    let data;

    try {
      const user = dataUser.get('data');

      const token: string = user.token;

      request = await axios.patch(
        '/provider/receiving/cargo',
        { ...receiving },
        {
          headers: { authorization: `Bearer ${token}` },
        },
      );

      data = request.data;

      const message = data.message;

      return { error: false, message };
    } catch (error: any) {
      const requestData = error;
      const data = requestData.response.data;
      if (!data) {
        return { error: true, message: 'Erro: O servidor está em manutenção.' };
      }
      const message = data.message;

      return { error: true, message };
    }
  },

  patchPayments: async (payments: ProviderCoconut[]) => {
    try {
      const dataPayments = {
        data: payments,
      };

      const user = dataUser.get('data');

      const token: string = user.token;

      const request = await axios.patch(
        '/provider/confirm/pay',
        { ...dataPayments },
        {
          headers: { authorization: `Bearer ${token}` },
        },
      );

      const data = request.data;

      const message: string = data.message as string;

      return { error: false, message };
    } catch (error: any) {
      const message: string | any = await serverError.check(error);
      return { error: true, message };
    }
  },

  patches: async (payments: ProviderCoconut[]) => {
    try {
      const dataPayments = {
        data: payments,
      };

      const user = dataUser.get('data');

      const token: string = user.token;

      const request = await axios.patch(
        '/provider-patches',
        { ...dataPayments },
        {
          headers: { authorization: `Bearer ${token}` },
        },
      );

      const data = request.data;

      const message: string = data.message as string;

      return { error: false, message };
    } catch (error: any) {
      const message: string | any = await serverError.check(error);
      return { error: true, message };
    }
  },

  delete: async (id: number) => {
    try {
      const user = dataUser.get('data');

      const token: string = user.token;

      const request = await axios.delete(`/provider/${id}`, {
        headers: { authorization: `Bearer ${token}` },
      });

      const data = request.data;

      const message: string = data.message as string;

      return { error: false, message };
    } catch (error: any) {
      const message: string | any = await serverError.check(error);
      return { error: true, message };
    }
  },
};
