import React from 'react';
import { dataUser } from '../../../config/user.conf';
import './menu.css';
import { Col, Menu, MenuProps, Row, theme } from 'antd';
import { AiOutlineHome, AiOutlineLogout } from 'react-icons/ai';
import { BsPencilFill, BsQuestionCircle } from 'react-icons/bs';

const { useToken } = theme;

export const MenuUI = () => {
  const { token } = useToken();

  const items: MenuProps['items'] = [
    {
      label: (
        <Row gutter={[5, 0]}>
          <Col>
            <h4>
              <AiOutlineHome />
            </h4>
          </Col>
          <Col>
            <h4>
              <a className="menu" href="/home">
                Home
              </a>
            </h4>
          </Col>
        </Row>
      ),
      key: 'home',
    },
    {
      label: (
        <Row gutter={[5, 0]}>
          <Col>
            <h4>
              <BsPencilFill />
            </h4>
          </Col>
          <Col>
            <h4>
              <a className="menu" href="/register">
                Cadastrar
              </a>
            </h4>
          </Col>
        </Row>
      ),
      key: 'register',
    },
    {
      label: (
        <Row gutter={[5, 0]}>
          <Col>
            <h4>
              <BsQuestionCircle />
            </h4>
          </Col>
          <Col>
            <h4>Sobre</h4>
          </Col>
        </Row>
      ),
      key: 'about',
      disabled: true,
    },
    {
      label: (
        <Row gutter={[5, 0]}>
          <Col>
            <h4>
              <AiOutlineLogout />
            </h4>
          </Col>
          <Col>
            <h4>
              <a
                className="menu"
                href="/"
                onClick={() => dataUser.remove('data')}
              >
                Sair
              </a>
            </h4>
          </Col>
        </Row>
      ),
      key: 'logout',
    },
  ];

  return (
    <Row justify={'end'}>
      <Col span={14}>
        <Menu
          className="border-0"
          style={{ backgroundColor: token.colorPrimaryBg }}
          mode="horizontal"
          items={items}
        />
      </Col>
    </Row>
  );
};
