import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, Row, notification } from 'antd';
import { productController } from '../../../../controller/products.controller';
import { IconType } from 'antd/es/notification/interface';
import br from 'antd/es/date-picker/locale/pt_BR';
import dayjs from 'dayjs';
import { ADMReceivingTable } from './adm.receiving.table';

const initialValues = {
  id: 0,
  precoLitro: '',
  debitarFrete: '',
  numeroNotaFiscal: '',
};

export const ADMReceivingScreen = () => {
  const [api, contextHolder] = notification.useNotification();

  const [values, setValues] = useState(initialValues);

  const [valuesTable, setValuesTable] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);

  const openNotification = (message: string, type: IconType) => {
    api.open({
      message: message,
      type: type,
      style: {
        backgroundColor: '#000',
      },
      duration: 5,
      placement: 'bottomRight',
    });
  };

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setValues({ ...values, [name]: value });
  }

  useEffect(() => {
    getProvisions();
  }, []);

  return (
    <Row justify={'center'} className="mt-5 mb-5 border border-4 rounded-4">
      {contextHolder}
      <Col span={22}>
        <Row justify={'center'} gutter={[0, 70]}>
          <Col className="mt-5">
            <h2>
              <strong>ADM Recebimento</strong>
            </h2>
          </Col>

          <Col span={24}>
            <Form
              id="cargo"
              initialValues={{ veiculo: 'Carreta' }}
              fields={[
                { name: 'precoLitro', value: values.precoLitro },
                { name: 'debitarFrete', value: values.debitarFrete },
                { name: 'numeroNotaFiscal', value: values.numeroNotaFiscal },
              ]}
              autoComplete="off"
              layout="vertical"
              onFinish={save}
            >
              <Row justify={'center'} gutter={[30, 0]}>
                <Col md={8}>
                  <Form.Item
                    label="Preço por Litro"
                    name="precoLitro"
                    rules={[{ required: true, message: 'Digite um valor...' }]}
                  >
                    <Input
                      name="precoLitro"
                      type="number"
                      disabled={values.id === 0}
                      onChange={handleChange}
                      placeholder="Digite..."
                      className="text-white"
                    />
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item label="Desconto De Frete" name="debitarFrete">
                    <Input
                      type="number"
                      placeholder="Digite..."
                      disabled={values.id === 0}
                      name="debitarFrete"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item
                    label="Numero da Nota Fiscal"
                    name="numeroNotaFiscal"
                    rules={[
                      {
                        required: true,
                        message: 'Digite um valor...',
                      },
                    ]}
                  >
                    <Input
                      disabled={values.id === 0}
                      type="number"
                      placeholder="Digite..."
                      name="numeroNotaFiscal"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Row justify={'center'} gutter={[5, 0]}>
                  <Col md={2}>
                    <Button type="primary" htmlType="submit" disabled={loading}>
                      Salvar
                    </Button>
                  </Col>
                  <Col md={2}>
                    <Button
                      type="default"
                      htmlType="reset"
                      onClick={() => {
                        setValues(initialValues);
                      }}
                      disabled={loading}
                    >
                      Limpar
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>

          <Col>
            <DatePicker
              defaultValue={dayjs()}
              onChange={(value) => {
                const date = dayjs(value).format('YYYY-MM-DD');

                getProvisions(date);
              }}
              format={'DD/MM/YYYY'}
              locale={br}
            />
          </Col>

          <ADMReceivingTable
            loading={loading}
            valuesTable={valuesTable}
            getRowValues={(values) => {
              setValues({
                id: values.id,
                ...values,
              });
            }}
          />
        </Row>
      </Col>
    </Row>
  );

  async function save() {
    const data = {
      id: values.id,
      precoLitro: values.precoLitro,
      debitarFrete: values.debitarFrete,
      numeroNotaFiscal: values.numeroNotaFiscal,
    };

    setLoading(true);

    const alright = await productController.patcheReceivingCargo(data);

    const message: string | any = alright.message;

    if (!alright.error) {
      openNotification(message, 'success');
    } else {
      openNotification(message, 'error');
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
    await getProvisions();
    setValues(initialValues);
  }

  async function getProvisions(value?: string) {
    const date = value ? value : dayjs().format('YYYY-MM-DD');

    setLoading(true);

    const data: any[] = await productController.getProvisions(date);

    if (data) {
      setValuesTable(data);
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }
};
