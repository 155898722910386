import React from 'react';
import './register.css';
import { RegisterProvidersScreen } from './register.providers.screen';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { RegisterAppointment } from './register.appointment';
import { ReceivingCargo } from './cargo/receiving.cargo';
import { CollectCargo } from './collect/collect.cargo';
import { ADMReceivingScreen } from './adm.receiving/adm.receiving.screen';

export const RegisterScreen = () => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <span>Agendamento</span>,
      children: <RegisterAppointment />,
    },
    {
      key: '2',
      label: <span>Recebimento Portaria</span>,
      children: <ReceivingCargo />,
    },
    {
      key: '3',
      label: <span>Recebimento MP</span>,
      children: <CollectCargo />,
    },
    {
      key: '4',
      label: <span>ADM Recebimento</span>,
      children: <ADMReceivingScreen />,
    },
    {
      key: '5',
      label: <span>Cadastro</span>,
      children: <RegisterProvidersScreen />,
    },
  ];

  return (
    <div className="container-home text w-100">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};
