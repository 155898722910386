import { notification } from 'antd';
import { IconType } from 'antd/es/notification/interface';
import React, { useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { userController } from '../../controller/user.controller';
import { Login } from '../../types/user';
import './login.css';

const initialValues: Login = {
  cpfCnpj: '',
  senha: '',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styleToast = {
  style: {
    backgroundColor: 'black',
    color: 'white',
  },
};

export const LoginScreen = () => {
  const [api, contextHolder] = notification.useNotification();

  const [values, setValues] = useState(initialValues);

  const [loading, setLoading] = useState(false);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setValues({ ...values, [name]: value });
  }

  const openNotification = (message: string, type: IconType) => {
    api.open({
      message: message,
      type: type,
      style: {
        backgroundColor: '#000',
      },
      duration: 5,
      placement: 'bottomRight',
    });
  };

  return (
    <div className="container-login">
      {contextHolder}
      <Form className="w-25" onSubmit={login}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Login</Form.Label>
          <Form.Control
            name="cpfCnpj"
            type="text"
            value={values.cpfCnpj}
            onChange={handleChange}
            placeholder="Digite o usuário"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Senha</Form.Label>
          <Form.Control
            name="senha"
            type="password"
            value={values.senha}
            onChange={handleChange}
            placeholder="Digite a senha"
          />
        </Form.Group>

        <Button
          className="me-5"
          variant="secondary"
          type="reset"
          disabled={loading}
          onClick={() => setValues({ ...initialValues })}
        >
          {!loading && <span>Limpar</span>}
          {loading && (
            <Spinner
              as="span"
              animation="grow"
              role="status"
              aria-hidden="true"
            />
          )}
        </Button>

        <Button variant="primary" type="submit" disabled={loading}>
          {!loading && <span>Entrar</span>}
          {loading && (
            <Spinner
              as="span"
              animation="grow"
              role="status"
              aria-hidden="true"
            />
          )}
        </Button>
      </Form>
    </div>
  );

  async function login(event: React.FormEvent) {
    event.preventDefault();

    setLoading(true);

    const data: Login = {
      cpfCnpj: values.cpfCnpj,
      senha: values.senha,
    };

    const alright = await userController.login(data);

    const message: string | any = alright.message;

    if (!alright.error) {
      openNotification(message, 'success');

      setTimeout(() => {
        document.location.reload();
      }, 2000);
    } else {
      openNotification(message, 'error');
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }
};
