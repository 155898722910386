import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Switch,
  notification,
} from 'antd';
import { productController } from '../../../../controller/products.controller';
import { IconType } from 'antd/es/notification/interface';
import br from 'antd/es/date-picker/locale/pt_BR';
import dayjs from 'dayjs';
import { CollectRegisterTable } from './collect.table';

const initialValues = {
  id: 0,
  fornecedor: '',
  motorista: '',
  placa: '',
  veiculo: '',
  cidade: '',
  liberada: false,
  precoLitro: '',
  numeroFicha: '',
  numeroCarrada: '',
  dataColheita: '',
  dataChegada: '',
};

export const CollectCargo = () => {
  const [api, contextHolder] = notification.useNotification();

  const [values, setValues] = useState(initialValues);

  const [valuesTable, setValuesTable] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);

  const openNotification = (message: string, type: IconType) => {
    api.open({
      message: message,
      type: type,
      style: {
        backgroundColor: '#000',
      },
      duration: 5,
      placement: 'bottomRight',
    });
  };

  useEffect(() => {
    getSchedulings();
  }, []);

  return (
    <Row justify={'center'} className="mt-5 mb-5 border border-4 rounded-4">
      {contextHolder}
      <Col span={22}>
        <Row justify={'center'} gutter={[0, 70]}>
          <Col className="mt-5">
            <h2>
              <strong>Recebimento de cargas</strong>
            </h2>
          </Col>

          <Col span={24}>
            <Form
              id="cargo"
              initialValues={{ veiculo: 'Carreta' }}
              fields={[
                { name: 'fornecedor', value: values.fornecedor },
                { name: 'motorista', value: values.motorista },
                { name: 'placa', value: values.placa },
                { name: 'veiculo', value: values.veiculo },
                { name: 'cidade', value: values.cidade },
                { name: 'precoLitro', value: values.precoLitro },
                { name: 'numeroCarrada', value: values.numeroCarrada },
                { name: 'liberada', value: values.liberada },
              ]}
              autoComplete="off"
              layout="vertical"
              onFinish={save}
            >
              <Row justify={'center'} gutter={[30, 0]}>
                <Col md={8}>
                  <Form.Item
                    label="Fornecedor"
                    name="fornecedor"
                    rules={[
                      { required: true, message: 'Selecione um fornecedor' },
                    ]}
                  >
                    <Input className="text-white" disabled />
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item
                    label="Motorista"
                    name="motorista"
                    rules={[
                      {
                        required: true,
                        message: 'Digite o motorista',
                        min: 4,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item
                    label="Placa do Veículo"
                    name="placa"
                    rules={[
                      { required: true, message: 'Por favor, digite a placa.' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify={'center'} gutter={[30, 0]}>
                <Col md={8}>
                  <Form.Item
                    label="Veículo"
                    name="veiculo"
                    rules={[{ message: 'Selecione o veículo' }]}
                  >
                    <Select
                      defaultValue="Carreta"
                      options={[
                        { value: 'Carreta', label: 'Carreta' },
                        { value: 'Bitruck', label: 'Bitruck' },
                        { value: 'Toco', label: 'Toco' },
                        { value: 'Truck', label: 'Truck' },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify={'center'} gutter={[30, 0]}>
                <Col md={6}>
                  <Form.Item
                    label="Origem do fruto (Cidade)"
                    name="cidade"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, digite a cidade.',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item label="Data de Colheita" name="dataColheita">
                    <DatePicker format={'DD/MM/YYYY'} locale={br} />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item label="Data de Chegada" name="dataChegada">
                    <DatePicker
                      name="dataChegada"
                      format={'DD/MM/YYYY HH:mm:ss'}
                      showTime
                      locale={br}
                    />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item label="Liberada" name="liberada">
                    <Switch title="Liberada" />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Row justify={'center'} gutter={[5, 0]}>
                  <Col md={2}>
                    <Button type="primary" htmlType="submit" disabled={loading}>
                      Salvar
                    </Button>
                  </Col>
                  <Col md={2}>
                    <Button
                      type="default"
                      htmlType="reset"
                      onClick={() => {
                        setValues(initialValues);
                      }}
                      disabled={loading}
                    >
                      Limpar
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>

          <Col>
            <DatePicker
              defaultValue={dayjs()}
              onChange={(value) => {
                getSchedulings(dayjs(value).format('YYYY-MM-DD'));
              }}
              format={'DD/MM/YYYY'}
              locale={br}
            />
          </Col>

          <CollectRegisterTable
            loading={loading}
            valuesTable={valuesTable}
            getRowValues={(values) => {
              setValues({
                id: values.id,
                fornecedor: values.fornecedor,
                placa: values.placa,
                veiculo: values.veiculo,
                motorista: values.motorista,
                cidade: values.cidade,
                liberada: values.liberada,
                precoLitro: values.precoLitro,
                numeroFicha: values.numeroFicha,
                dataColheita: values.dataColheita,
                dataChegada: values.dataChegada,
                numeroCarrada: values.numeroCarrada,
              });
            }}
            disapprovedCargo={(id) => {
              disapproved(id);
            }}
          />
        </Row>
      </Col>
    </Row>
  );

  async function save(formValues: any) {
    const data = {
      id: values.id,
      motorista: (formValues.motorista as string).toUpperCase(),
      placa: (formValues.placa as string).toUpperCase(),
      veiculo: formValues.veiculo,
      cidade: (formValues.cidade as string).toUpperCase(),
      precoLitro: formValues.precoLitro,
      numeroEntrega: formValues.numeroEntrega,
      liberada: formValues.liberada,
      dataColheita: dayjs(formValues.dataColheita).format('YYYY-MM-DD'),
      dataChegada: dayjs(formValues.dataChegada).format('YYYY-MM-DD HH:mm:ss'),
    };

    setLoading(true);

    const alright = await productController.patcheReceivingCargo(data);

    const message: string | any = alright.message;

    if (!alright.error) {
      openNotification(message, 'success');
      setValues(initialValues);
    } else {
      openNotification(message, 'error');
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
    await getSchedulings();
  }

  async function disapproved(id: number) {
    const data = {
      id: id,
      aprovada: false,
    };

    const alright = await productController.patcheReceivingCargo(data);

    const message: string | any = alright.message;

    if (!alright.error) {
      openNotification(message, 'success');
      setValues(initialValues);
    } else {
      openNotification(message, 'error');
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
    await getSchedulings();
  }

  async function getSchedulings(value?: string) {
    setValuesTable([]);
    const date = value ? value : dayjs().format('YYYY-MM-DD');

    setLoading(true);

    const data: any[] = await productController.getScheduling(date);

    setValuesTable(data);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }
};
