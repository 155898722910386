export const StringFormatter = {
  onlyNumber: (value: string) => {
    const data = value.replace(/[^\d]+/g, '');
    return data;
  },
  realNumber: (value: number) => {
    const data = value.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL',
    });
    return data + ' ';
  },
  datePTBrToEUA: (date: string) => {
    const value = date.split('/');
    const newDate = value[2] + '-' + value[1] + '-' + value[0];

    return newDate;
  },
};
