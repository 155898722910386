import axios from '../config/axios';
import { dataUser } from '../config/user.conf';
import { Login, ProviderUser } from '../types/user';
import { serverError } from './errors/axios.errors.controller';

export const userController = {
  login: async (dataLogin: Login) => {
    let request;
    let data;

    try {
      request = await axios.post('/login', dataLogin);

      data = request.data;

      const message = data.message;

      const user = data.user;

      dataUser.store('data', user);

      return { error: false, message };
    } catch (error: any) {
      const requestData = error;
      const data = requestData.response.data;
      if (!data) {
        return { error: true, message: 'Erro: O servidor está em manutenção.' };
      }
      const message = data.message;

      return { error: true, message };
    }
  },

  store: async (values: ProviderUser) => {
    let request;
    let data;

    try {
      const valuesToStore = {
        cpfCnpj: values.cpfCnpj,
        nome: values.nome,
        senha: values.senha,
        userType: values.userType,
      };

      const user = dataUser.get('data');

      const token: string = user.token;

      request = await axios.post('/user', valuesToStore, {
        headers: { authorization: `Bearer ${token}` },
      });

      data = request.data;

      const message = data.message;

      return { error: false, message };
    } catch (error: any) {
      const requestData = error;
      const data = requestData.response.data;
      if (!data) {
        return { error: true, message: 'Erro: O servidor está em manutenção.' };
      }
      const message = data.message;

      return { error: true, message };
    }
  },

  patchs: async (dataUsers: ProviderUser[]) => {
    let request;
    let data;

    const usersData = {
      data: dataUsers,
    };

    try {
      const user = dataUser.get('data');

      const token: string = user.token;

      request = await axios.patch(
        '/users',
        { ...usersData },
        {
          headers: { authorization: `Bearer ${token}` },
        },
      );

      data = request.data;

      const message = data.message;

      return { error: false, message };
    } catch (error: any) {
      const requestData = error;
      const data = requestData.response.data;
      if (!data) {
        return { error: true, message: 'Erro: O servidor está em manutenção.' };
      }
      const message = data.message;

      return { error: true, message };
    }
  },

  get: async () => {
    try {
      const user = dataUser.get('data');

      const token: string = user.token;

      const request = await axios.get('/user', {
        headers: { authorization: `Bearer ${token}` },
      });

      const data = request.data;

      return data.data;
    } catch (error) {
      const message: string | any = await serverError.check(error);
      return { error: true, message };
    }
  },

  getProvider: async () => {
    try {
      const user = dataUser.get('data');

      const token: string = user.token;

      const request = await axios.get('/user-provider', {
        headers: { authorization: `Bearer ${token}` },
      });

      const data = request.data;

      return data.data;
    } catch (error) {
      const message: string | any = await serverError.check(error);
      return { error: true, message };
    }
  },
};
