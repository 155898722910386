import React from 'react';
import './App.css';
import { Navigation } from './app/app.navigation';
import 'bootstrap/dist/css/bootstrap.min.css';

const App: React.FC = () => (
  <div className="App">
    <Navigation />
  </div>
);

export default App;
