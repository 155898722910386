export const serverError = {
  check: async (error: any) => {
    const request = error;

    const data = request.response.data;
    if (!data) {
      const message = 'Erro: O servidor está em manutenção.';
      return message;
    }

    const message = data.message;
    return message;
  },
};
