import React from 'react';
import { Image } from 'react-bootstrap';
import { images } from '../../../config/images.path';
import './header.css';
import { Col, Row } from 'antd';
import { Menu } from '../menu';

export const HeaderUI = () => {
  return (
    <Row>
      <Col md={5}>
        <p className="ms-4">
          <Image className="logo" src={images.logo} />
        </p>
      </Col>
      <Col md={18}>
        <Menu />
      </Col>
    </Row>
  );
};
