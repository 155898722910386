import React from 'react';
import { Card, Col, List, Row } from 'antd';
import { ProductEntry } from '../../../types/providersCoconut';
import dayjs from 'dayjs';
import { StringFormatter } from '../../../util/string.formatter/string.formatter';

type Props = {
  commodity: ProductEntry[];
  loadingData: boolean;
};

export const HomeMobileList = (props: Props) => {
  const commodity = props.commodity;

  return (
    <Row>
      <Col
        span={24}
        style={{ height: 300, overflowY: 'auto' }}
        className="border rounded-2"
      >
        <List
          grid={{
            gutter: 0,
          }}
          dataSource={commodity}
          renderItem={(item) => (
            <List.Item>
              <Card title={'Carrada'}>
                <Row>
                  <Col span={24} style={{ fontSize: 20 }}>
                    Litros: {item.volumeListros}
                  </Col>
                  <Col span={24} style={{ fontSize: 20 }}>
                    Descarregamento:{' '}
                    {dayjs(item.dataDescarregamento).format('DD/MM/YYYY')}
                  </Col>
                  <Col span={24} style={{ fontSize: 20 }}>
                    Pago: {paidOut(item.pago)}
                  </Col>
                  <Col span={24} style={{ fontSize: 20 }}>
                    Valor: {StringFormatter.realNumber(item.aPagar)}
                  </Col>
                </Row>
              </Card>
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );

  function paidOut(paid: boolean): string {
    if (paid) {
      return 'Sim';
    }

    return 'Não';
  }
};
