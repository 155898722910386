import { Button, Col, Input, InputRef, Row, Space, Table } from 'antd';
import {
  ColumnType,
  ColumnsType,
  FilterConfirmProps,
  FilterValue,
} from 'antd/es/table/interface';
import { TableProps } from 'antd/lib';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import { AiFillEdit, AiOutlineSearch } from 'react-icons/ai';

interface DataType {
  key: number;
  id: number;
  cpfCnpj: string;
  nome: string;
  dataAgendada: string;
  debitarFrete: number;
}

type DataIndex = keyof DataType;

interface Props {
  valuesTable: any[];
  loading: boolean;
  getRowValues: (values: any) => any;
}

export const ADMReceivingTable = (props: Props) => {
  const data = props.valuesTable;
  const loading = props.loading;

  const searchInput = useRef<InputRef>(null);

  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const handleChange: TableProps<DataType>['onChange'] = (
    pagination,
    filters,
  ) => {
    setFilteredInfo(filters);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Pesquisar...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], () =>
                confirm({ closeDropdown: false }),
              )
            }
            icon={<AiOutlineSearch />}
            size="small"
            style={{ width: 90 }}
          >
            Pesquisar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <AiOutlineSearch
        size={20}
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    render: (text) => text,
  });

  const columns: ColumnsType<DataType> = [
    {
      key: 'cpfCnpj',
      title: 'CPF/Cnpj',
      dataIndex: 'cpfCnpj',
      filteredValue: filteredInfo.cpfCnpj || null,
      ...getColumnSearchProps('cpfCnpj'),
    },
    {
      key: 'nome',
      title: 'Fornecedor',
      dataIndex: 'nome',
      filteredValue: filteredInfo.nome || null,
      sorter: (a, b) => a.nome.localeCompare(b.nome),
      showSorterTooltip: {
        title: 'Clique para alterar a ordem.',
        color: '#000',
      },
      ...getColumnSearchProps('nome'),
    },
    {
      key: 'numeroNotaFiscal',
      title: 'Nº Nota',
      dataIndex: 'numeroNotaFiscal',
      filteredValue: null,
    },
    {
      key: 'precoLitro',
      title: 'Preço Litro',
      dataIndex: 'precoLitro',
      render: (data: number) => (
        <div>
          {data.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
        </div>
      ),
      filteredValue: null,
    },
    {
      key: 'debitarFrete',
      title: 'Debitar Frete',
      dataIndex: 'debitarFrete',
      render: (data: number) => (
        <div>
          {data.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
        </div>
      ),
      filteredValue: null,
    },
    {
      key: 'dataAgendada',
      title: 'Data',
      dataIndex: 'dataAgendada',
      filteredValue: null,
    },
    {
      title: 'Operação',
      key: 'operation',
      fixed: 'right',
      render: (data: DataType) => (
        <Button
          href="#cargo"
          title="Editar"
          onClick={() => {
            props.getRowValues({
              id: data.id,
            });
          }}
        >
          <AiFillEdit size={20} title="Editar" />
        </Button>
      ),
    },
  ];

  return (
    <Row>
      <Col>
        <Table
          onChange={handleChange}
          scroll={{ y: 400 }}
          loading={loading}
          columns={columns}
          dataSource={startDataType()}
          pagination={{
            defaultPageSize: 10,
            pageSizeOptions: [10, 20, 100],
            showTotal: () => (
              <div className="text-white">
                <strong>Total: {data.length}</strong>
              </div>
            ),
          }}
        />
      </Col>
    </Row>
  );

  function startDataType() {
    const newData: any = data.map((value) => {
      const date = dayjs(value.dataAgendada).format('DD/MM/YYYY');
      const debitarFrete = value.debitarFrete ? value.debitarFrete : 0;
      const precoLitro = value.precoLitro ? value.precoLitro : 0;

      return {
        key: value.id,
        id: value.id,
        cpfCnpj: value.cpfCnpj,
        nome: value.nome,
        dataAgendada: date,
        debitarFrete: debitarFrete,
        precoLitro: precoLitro,
        numeroNotaFiscal: value.numeroNotaFiscal,
      };
    });

    return newData;
  }
};
