import React from 'react';
import './footer.css';

export const FooterUI = () => {
  return (
    <div className="container-footer">
      <p>Paraipaba Agroindustrial Ltda.</p>
    </div>
  );
};
