import React, { useState, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import ptBr from 'antd/locale/pt_BR';
import './index.css';
import App from './App';

const container = document.getElementById('root') as any;
const root = createRoot(container);

function Index() {
  const locale = ptBr;

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDarkMode(true);
    }, 100);
  }, [darkMode]);

  return (
    <BrowserRouter>
      {darkMode && (
        <ConfigProvider
          locale={locale}
          theme={{
            token: {
              colorPrimaryBg: '#282c34',
              colorText: '#fff',
              colorBorderSecondary: '#fff',
              colorBgBase: '#282c31',
              colorBgContainer: '#282c30',
              colorTextSecondary: '#fff',
              colorIcon: '#fff',
              colorIconHover: '#fff',
              colorTextPlaceholder: '#fff',
            },
          }}
        >
          <App />
        </ConfigProvider>
      )}
    </BrowserRouter>
  );
}

root.render(<Index />);
