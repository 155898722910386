/* eslint-disable array-callback-return */

import React, { useEffect, useRef, useState } from 'react';
import { ListGroup, Spinner } from 'react-bootstrap';
import { ProviderCoconut } from '../../../../types/providersCoconut';
import { notification, Table, Space, Button, Row, Col, Popconfirm } from 'antd';
import { productController } from '../../../../controller/products.controller';
import {
  ColumnType,
  FilterConfirmProps,
  FilterValue,
  TableRowSelection,
} from 'antd/es/table/interface';
import Input, { InputRef } from 'antd/es/input';
import { AiOutlineSearch } from 'react-icons/ai';
import { ColumnsType, TableProps } from 'antd/es/table/InternalTable';
import { IconType } from 'antd/es/notification/interface';
import { BsFillTrashFill } from 'react-icons/bs';
import './home.css';

interface DataType {
  key: number;
  id: number;
  cpfCnpj: string;
  numeroNotaFiscal: number;
  nome: string;
  dataDescarregamento: string;
  aPagar: string;
  observacoes: string;
  acrescimoFrete: number;
  debitarFrete: number;
  volumeLitros: number;
  precoLitro: number;
}

type DataIndex = keyof DataType;

const initialProvidersValues: ProviderCoconut[] = [];

export const HomeDataScreen = () => {
  const searchInput = useRef<InputRef>(null);

  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});

  const [api, contextHolder] = notification.useNotification();

  const [valuesProviders, setValuesProviders] = useState<ProviderCoconut[]>(
    initialProvidersValues,
  );

  const [valuesToSave, setValuesToSave] = useState<DataType[]>([]);

  const [data, setData] = useState<DataType[]>([]);

  const [loading, setLoading] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const handleChange: TableProps<DataType>['onChange'] = (
    pagination,
    filters,
  ) => {
    setFilteredInfo(filters);
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
  ) => {
    setLoading(true);
    confirm();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const clearAllFilters = () => {
    setFilteredInfo({});
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Pesquisar...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            handleSearch(selectedKeys as string[], confirm);
          }}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], () =>
                confirm({ closeDropdown: false }),
              )
            }
            icon={<AiOutlineSearch />}
            size="small"
            style={{ width: 90 }}
          >
            Pesquisar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <AiOutlineSearch
        size={20}
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    render: (text) => text,
  });

  const columns: ColumnsType<DataType> = [
    {
      key: 'cpfCnpj',
      width: 80,
      title: 'CPF/Cnpj',
      dataIndex: 'cpfCnpj',
      filteredValue: filteredInfo.cpfCnpj || null,
      fixed: 'left',
      ...getColumnSearchProps('cpfCnpj'),
    },
    {
      key: 'nome',
      title: 'Fornecedor',
      width: 100,
      dataIndex: 'nome',
      filteredValue: filteredInfo.nome || null,
      sorter: (a, b) => a.nome.localeCompare(b.nome),
      fixed: 'left',
      ...getColumnSearchProps('nome'),
    },
    {
      key: 'numeroNotaFiscal',
      width: 80,
      title: 'Nf-e',
      sorter: (a, b) => a.numeroNotaFiscal - b.numeroNotaFiscal,
      filteredValue: filteredInfo.numeroNotaFiscal || null,
      render: (values: DataType) => {
        return (
          <Input
            type="number"
            onChange={(value: any) => {
              const numeroNotaFiscal = Number.parseFloat(value.target.value);
              values.numeroNotaFiscal = numeroNotaFiscal;

              let payment: ProviderCoconut = valuesProviders.filter(
                (value) => value.id === values.id,
              )[0];

              payment = { ...payment, numeroNotaFiscal: numeroNotaFiscal };

              storeAlterTable(payment);
            }}
            defaultValue={values.numeroNotaFiscal}
          />
        );
      },
    },
    {
      key: 'dataDescarregamento',
      width: 100,
      title: 'Data de Entrega',
      sorter: (a, b) => {
        const dateA = a.dataDescarregamento
          .split('/')
          .reverse()
          .join('-')
          .replaceAll('-', '');

        const dateB = b.dataDescarregamento
          .split('/')
          .reverse()
          .join('-')
          .replaceAll('-', '');

        const dateNumberA = Number.parseInt(dateA);
        const dateNumberB = Number.parseInt(dateB);
        const result = dateNumberB - dateNumberA;
        return result;
      },
      filteredValue: filteredInfo.dataDescarregamento || null,
      render: (values: DataType) => {
        return (
          <>
            <Input
              type="date"
              className="m-0"
              onChange={(date: any) => {
                const dataDescarregamento = date.target.value;
                values.dataDescarregamento = dataDescarregamento;

                let payment: ProviderCoconut = valuesProviders.filter(
                  (value) => value.id === values.id,
                )[0];

                payment = {
                  ...payment,
                  dataDescarregamento: dataDescarregamento,
                };

                storeAlterTable(payment);
              }}
              defaultValue={values.dataDescarregamento}
            />
          </>
        );
      },
    },
    {
      key: 'volumeLitros',
      width: 70,
      title: 'Volume L',
      filteredValue: null,
      render: (values: DataType) => {
        return (
          <Input
            type="number"
            onChange={(value: any) => {
              const volumeLitros = Number.parseFloat(value.target.value);
              values.volumeLitros = volumeLitros;

              let payment: ProviderCoconut = valuesProviders.filter(
                (value) => value.id === values.id,
              )[0];

              payment = { ...payment, volumeLitros: volumeLitros };

              storeAlterTable(payment);
            }}
            defaultValue={values.volumeLitros}
          />
        );
      },
    },
    {
      key: 'precoLitro',
      width: 70,
      title: 'Preço L',
      filteredValue: null,
      render: (values: DataType) => {
        return (
          <Input
            type="number"
            onChange={(value: any) => {
              const precoLitro = Number.parseFloat(value.target.value);
              values.precoLitro = precoLitro;

              let payment: ProviderCoconut = valuesProviders.filter(
                (value) => value.id === values.id,
              )[0];

              payment = { ...payment, precoLitro: precoLitro };

              storeAlterTable(payment);
            }}
            defaultValue={values.precoLitro}
          />
        );
      },
    },
    {
      key: 'debitarFrete',
      width: 70,
      title: 'Debitar',
      filteredValue: filteredInfo.debitarFrete || null,
      render: (values: DataType) => {
        return (
          <Input
            type="number"
            onChange={(value: any) => {
              const debitarFrete = Number.parseFloat(value.target.value);
              values.debitarFrete = debitarFrete;

              let payment: ProviderCoconut = valuesProviders.filter(
                (value) => value.id === values.id,
              )[0];

              payment = { ...payment, debitarFrete: debitarFrete };

              storeAlterTable(payment);
            }}
            defaultValue={values.debitarFrete}
          />
        );
      },
    },
    {
      key: 'acrescimoFrete',
      width: 70,
      title: 'Acrescimo',
      filteredValue: filteredInfo.acrescimoFrete || null,
      render: (values: DataType) => {
        return (
          <Input
            type="number"
            onChange={(value: any) => {
              const acrescimoFrete = Number.parseFloat(value.target.value);
              values.acrescimoFrete = acrescimoFrete;

              let payment: ProviderCoconut = valuesProviders.filter(
                (value) => value.id === values.id,
              )[0];

              payment = { ...payment, acrescimoFrete: acrescimoFrete };

              storeAlterTable(payment);
            }}
            defaultValue={values.acrescimoFrete}
          />
        );
      },
    },
    {
      title: 'Operação',
      key: 'operation',
      fixed: 'right',
      width: 50,
      render: (values: DataType) => (
        <Popconfirm
          title="Deletar"
          placement="topRight"
          description="Deseja realmente deletar?"
          onConfirm={() => remove(values.id)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link">
            <BsFillTrashFill size={20} color="#f75959" title="Excluir" />
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const rowSelection: TableRowSelection<DataType> = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setValuesToSave(selectedRows);
    },
    selectedRowKeys,
  };

  const openNotification = (message: string, type: IconType) => {
    api.open({
      message: message,
      type: type,
      style: {
        backgroundColor: '#000',
      },
      duration: 5,
      placement: 'bottomRight',
    });
  };

  useEffect(() => {
    getProductsNoPayables();
  }, []);

  return (
    <Row justify={'center'}>
      {contextHolder}
      <Col span={24}>
        <div className="row justify-content-center text-center m-5">
          <div className="col">
            <h3>
              <strong>Dados dos Produtos</strong>
            </h3>
          </div>
        </div>

        <div className="row justify-content-center text-center m-5">
          <div className="col-2">
            <Button onClick={save} disabled={loading}>
              {!loading && <span>Salvar</span>}
              {loading && (
                <Spinner
                  as="span"
                  animation="grow"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>

          <div className="col-2">
            <Button onClick={clearAllFilters} disabled={loading}>
              {!loading && <span>Limpar</span>}
              {loading && (
                <Spinner
                  as="span"
                  animation="grow"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>

          <div className="col-2">
            <Button onClick={getProductsNoPayables} disabled={loading}>
              {!loading && <span>Atualizar</span>}
              {loading && (
                <Spinner
                  as="span"
                  animation="grow"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
        </div>

        <Row justify={'center'}>
          <Col span={23}>
            <Table
              size="large"
              onChange={handleChange}
              loading={loading}
              scroll={{ x: 1500, y: 300 }}
              columns={columns}
              rowSelection={{ ...rowSelection }}
              expandable={{
                expandedRowRender: (record) => (
                  <ListGroup>
                    <Row className="background">
                      <Col md={6}>
                        <ListGroup.Item className="background border-0">
                          <p style={{ margin: 0 }}>
                            Observações: {record.observacoes}
                          </p>
                        </ListGroup.Item>
                      </Col>
                      <Col md={6}>
                        <ListGroup.Item className="background border-0">
                          <p style={{ margin: 0 }}>
                            Debito:{' '}
                            {record.debitarFrete + record.acrescimoFrete}
                          </p>
                        </ListGroup.Item>
                      </Col>
                    </Row>
                  </ListGroup>
                ),
              }}
              dataSource={data}
              pagination={{
                defaultPageSize: 10,
                pageSizeOptions: [10, 20, 100],
                showTotal: () => (
                  <div className="text-white">
                    <strong>Notas: {data.length}</strong>
                  </div>
                ),
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );

  async function save() {
    setLoading(true);

    const values: any[] = valuesToSave;

    if (!values.length) {
      openNotification('Nenhum dado foi alterado.', 'warning');
      getProductsNoPayables();
      return;
    }

    const alright = await productController.patches(values);

    const message: string | any = alright.message;

    if (!alright.error) {
      values.length = 0;
      openNotification(message, 'success');
    } else {
      openNotification(message, 'error');
    }

    getProductsNoPayables();
    clearAllFilters();
  }

  async function remove(id: number) {
    setLoading(true);

    const values: any[] = valuesToSave;

    const alright = await productController.delete(id);

    const message: string | any = alright.message;

    if (!alright.error) {
      values.length = 0;
      openNotification(message, 'success');
    } else {
      openNotification(message, 'error');
    }

    getProductsNoPayables();
    clearAllFilters();
  }

  async function getProductsNoPayables() {
    setLoading(true);

    setData([]);

    setSelectedRowKeys([]);

    const data: ProviderCoconut[] = await productController.getNoPayables();

    setValuesProviders(data);

    startDataType(data);
  }

  function storeAlterTable(pay: ProviderCoconut) {
    const payment: ProviderCoconut = pay;

    const value = valuesProviders.filter((value) => value.id === payment.id)[0];

    const index = valuesProviders.indexOf(value);

    valuesProviders.splice(index, 1, { ...payment });
  }

  function startDataType(providers: ProviderCoconut[]) {
    const data: DataType[] = [];

    providers.map((value, index) => {
      data.push({
        key: index,
        id: value.id,
        aPagar: value.aPagar.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
        cpfCnpj: value.cpfCnpj,
        dataDescarregamento: value.dataChegada.toString(),
        nome: value.nome,
        numeroNotaFiscal: value.numeroNotaFiscal,
        observacoes: value.observacoes,
        acrescimoFrete: value.acrescimoFrete,
        debitarFrete: value.debitarFrete,
        volumeLitros: value.volumeLitros,
        precoLitro: value.precoLitro,
      });
    });

    setTimeout(() => {
      setLoading(false);
      setData(data);
    }, 1000);
  }
};
