import React from 'react';
import { HomeNoPaymentsScreen } from './home.no.payments.screen';
import { HomePaymentsScreen } from './home.payments.screen';
import { HomeUploadCSVScreen } from './home.upload.csv.screen';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { HomeDataScreen } from './home.data.screen';

export const HomeScreen = () => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <span>Pagamentos</span>,
      children: <HomeNoPaymentsScreen />,
    },
    {
      key: '2',
      label: `Pagos`,
      children: <HomePaymentsScreen />,
    },
    {
      key: '3',
      label: `Dados`,
      children: <HomeDataScreen />,
    },
    {
      key: '4',
      label: `Upload`,
      children: <HomeUploadCSVScreen />,
    },
  ];

  return (
    <div className="mt-5 mb-5">
      <Tabs defaultActiveKey="1" className="m-2" items={items} />
    </div>
  );
};
