import React, { useEffect, useState } from 'react';
import { AppRoutes } from './routes/routes';
import { dataUser } from './config/user.conf';

export const Navigation = () => {
  const user = dataUser.get('data');
  const [screenMobile, setScreenMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const sizeMobile: boolean = window.innerWidth < 930;
      setScreenMobile(sizeMobile);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
  });

  return <AppRoutes screenMobile={screenMobile} dataUser={user} />;
};
