import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputRef,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  TableProps,
  notification,
} from 'antd';
import { ProviderUser } from '../../../types/user';
import { userController } from '../../../controller/user.controller';
import { productController } from '../../../controller/products.controller';
import { IconType } from 'antd/es/notification/interface';
import {
  ColumnType,
  ColumnsType,
  FilterConfirmProps,
  FilterValue,
} from 'antd/es/table/interface';
import { AiOutlineSearch } from 'react-icons/ai';
import br from 'antd/es/date-picker/locale/pt_BR';
import dayjs from 'dayjs';
import { BsFillTrashFill } from 'react-icons/bs';

interface DataType {
  key: number;
  id: number;
  cpfCnpj: string;
  nome: string;
  dataAgendada: string;
  numeroFicha: number;
}

type DataIndex = keyof DataType;

const initialProvidersValues: ProviderUser[] = [];

export const RegisterAppointment = () => {
  const searchInput = useRef<InputRef>(null);

  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});

  const [data, setData] = useState<DataType[]>([]);

  const [valuesProviders, setValuesProviders] = useState<ProviderUser[]>(
    initialProvidersValues,
  );

  const [api, contextHolder] = notification.useNotification();

  const [loading, setLoading] = useState(false);

  const openNotification = (message: string, type: IconType) => {
    api.open({
      message: message,
      type: type,
      style: {
        backgroundColor: '#000',
      },
      duration: 5,
      placement: 'bottomRight',
    });
  };

  const handleChange: TableProps<DataType>['onChange'] = (
    pagination,
    filters,
  ) => {
    setFilteredInfo(filters);
  };

  const clearAllFilters = () => {
    setFilteredInfo({});
  };

  const handleSearch: any = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Pesquisar...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], () =>
                confirm({ closeDropdown: false }),
              )
            }
            icon={<AiOutlineSearch />}
            size="small"
            style={{ width: 90 }}
          >
            Pesquisar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <AiOutlineSearch
        size={20}
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    render: (text) => text,
  });

  const columns: ColumnsType<DataType> = [
    {
      key: 'cpfCnpj',
      title: 'CPF/Cnpj',
      dataIndex: 'cpfCnpj',
      filteredValue: filteredInfo.cpfCnpj || null,
      ...getColumnSearchProps('cpfCnpj'),
    },
    {
      key: 'nome',
      title: 'Fornecedor',
      dataIndex: 'nome',
      filteredValue: filteredInfo.nome || null,
      sorter: (a, b) => a.nome.localeCompare(b.nome),
      showSorterTooltip: {
        title: 'Clique para alterar a ordem.',
        color: '#000',
      },
      ...getColumnSearchProps('nome'),
    },
    {
      key: 'numeroFicha',
      title: 'Numero',
      dataIndex: 'numeroFicha',
      sorter: (a, b) => a.numeroFicha - b.numeroFicha,
      filteredValue: null,
    },
    {
      key: 'dataAgendada',
      title: 'Data',
      dataIndex: 'dataAgendada',
      filteredValue: null,
    },
    {
      title: 'Operação',
      key: 'operation',
      fixed: 'right',
      render: (values: DataType) => (
        <Popconfirm
          title="Deletar"
          placement="topLeft"
          description="Deseja realmente deletar?"
          onConfirm={() => remove(values.id)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link">
            <BsFillTrashFill size={20} color="#f75959" title="Excluir" />
          </Button>
        </Popconfirm>
      ),
    },
  ];

  useEffect(() => {
    getProviders();
    getSchedulings();
  }, []);

  return (
    <Row justify={'center'} className="mt-5 mb-5 border border-4 rounded-4">
      {contextHolder}
      <Col span={22}>
        <Row justify={'center'} gutter={[0, 70]}>
          <Col className="mt-5">
            <h2>
              <strong>Agendamento de Carradas</strong>
            </h2>
          </Col>

          <Col span={24}>
            <Form
              initialValues={{
                remember: true,
                dataAgendamento: dayjs(),
              }}
              autoComplete="off"
              layout="horizontal"
              onFinish={save}
            >
              <Row justify={'center'} gutter={[10, 0]}>
                <Col md={18}>
                  <Form.Item
                    label="Fornecedor"
                    name="fornecedor"
                    rules={[
                      { required: true, message: 'Selecione um fornecedor' },
                    ]}
                  >
                    <Select
                      options={valuesProviders.map((value) => {
                        return {
                          key: value.cpfCnpj,
                          value: value.nome + ',' + value.cpfCnpj,
                          label: value.nome + ' : ' + value.cpfCnpj,
                        };
                      })}
                      mode="multiple"
                    />
                  </Form.Item>
                </Col>
                <Col md={4}>
                  <Form.Item
                    label="Data"
                    name="dataAgendamento"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, selecione a data.',
                      },
                    ]}
                  >
                    <DatePicker format={'DD/MM/YYYY'} locale={br} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Row justify={'center'} gutter={[5, 0]}>
                  <Col md={2}>
                    <Button type="primary" loading={loading} htmlType="submit">
                      Salvar
                    </Button>
                  </Col>
                  <Col md={2}>
                    <Button
                      type="default"
                      htmlType="reset"
                      loading={loading}
                      onClick={getProviders}
                    >
                      Limpar
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>

          <Col>
            <DatePicker
              defaultValue={dayjs()}
              onChange={(value) => {
                getSchedulings(dayjs(value).format('YYYY-MM-DD'));
              }}
              format={'DD/MM/YYYY'}
              locale={br}
            />
          </Col>

          <Col>
            <Table
              onChange={handleChange}
              scroll={{ y: 400 }}
              loading={loading}
              columns={columns}
              dataSource={initTable()}
              pagination={{
                defaultPageSize: 10,
                pageSizeOptions: [10, 20, 100],
                showTotal: () => (
                  <div className="text-white">
                    <strong>Total: {data.length}</strong>
                  </div>
                ),
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );

  async function save(values: any) {
    const date = dayjs(values.dataAgendamento).format('YYYY-MM-DD');

    const data = {
      data: values.fornecedor,
      date: date,
    };

    const alright = await productController.storeScheduling(data);

    const message: string | any = alright.message;

    if (!alright.error) {
      openNotification(message, 'success');
    } else {
      openNotification(message, 'error');
    }

    setTimeout(() => {
      getSchedulings();
    }, 500);
  }

  async function getProviders() {
    setValuesProviders([]);

    setLoading(true);

    const data: ProviderUser[] = await userController.getProvider();

    setValuesProviders(data);

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  async function getSchedulings(value?: string) {
    setData([]);
    setLoading(true);

    const date = value ? value : dayjs().format('YYYY-MM-DD');

    const data: any[] = await productController.getScheduling(date);

    setData(data);

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  async function remove(id: number) {
    const alright = await productController.delete(id);

    const message: string | any = alright.message;

    if (!alright.error) {
      openNotification(message, 'success');
    } else {
      openNotification(message, 'error');
    }

    clearAllFilters();

    setTimeout(() => {
      getSchedulings();
    }, 500);
  }

  function initTable() {
    const newData: any = data.map((value, index) => {
      const date = dayjs(value.dataAgendada).format('DD/MM/YYYY');
      return {
        id: value.id,
        key: index,
        cpfCnpj: value.cpfCnpj,
        nome: value.nome,
        numeroFicha: value.numeroFicha,
        dataAgendada: date,
      };
    });

    return newData;
  }
};
